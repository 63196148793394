* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: 'Wix Madefor Display', sans-serif;
}

::selection {
  background-color: #e5f3fc;
}

input,
textarea {
  font-family: 'Wix Madefor Display', sans-serif !important;
  font-size: 15px !important;
}

input::placeholder {
  font-size: 14px;
  color: rgba(79, 89, 96, 0.6);
}

.System {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: absolute;
  z-index: 3000;
  box-shadow:
    rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;

  width: 400px;
  height: 500px;
  border-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-color: white;
  transition: all 600ms;

  top: 50%;
  left: 100%;
  margin-left: 0px;
  margin-top: -250px;
  overflow: hidden;
}

body {
  background-image: url(../public/img/autorization.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;
}

body > iframe {
  display: none;
}

.home {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;

  max-width: 1940px;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;

  box-shadow:
    0 1px 2px rgba(62, 62, 62, 0.12),
    0 1px 2px rgba(62, 62, 62, 0.12);
}

.main_box,
.header {
  width: 100%;
}

.main_box {
  display: flex;
  margin-top: 0px;
}

.main {
  height: calc(100vh - 55px);
  width: calc(100% - 130px);

  height: calc(100vh - 0px);
  width: calc(100% - 0px);

  padding: 20px;
  padding: 14px;
  overflow-y: scroll;
  margin-left: 0px;
  background-color: #f8f9fc;
}

.header {
  width: 100%;
  height: 55px;
  display: flex;
  position: sticky;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #ffffff;

  box-shadow:
    0 1px 2px rgba(62, 62, 62, 0.12),
    0 1px 2px rgba(62, 62, 62, 0.12);
  z-index: 20;
  padding-left: 18px;
  padding-right: 11px;
}

.flexContainer {
  display: flex;
}

.flexContainerWithGap {
  display: flex;
  align-items: flex-start;
  position: relative;
  gap: 12px;
}

.width67Percent {
  width: calc(65.6% + 10px);
}

.width50Percent {
  width: 50%;
}

.width32Percent {
  width: 32.8%;
}

.width24Point5Percent {
  width: 24.5%;
}

h2 {
  font-size: 20px !important;
  font-weight: 800 !important;
  line-height: 24px !important;
  color: #132532;
}

h3 {
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 24px !important;
  color: #132532;
}

h4 {
  font-size: 15px;
  line-height: 24px !important;
}

h5,
h6 {
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
}

p,
h1,
h2,
h3,
h4,
h5 {
  color: #4f5960;
  color: #43484d;
}

p {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
}

span {
  font-size: 15px;
}

@media (max-width: 1050px) {
  .width32Percent,
  .width24Point5Percent,
  .width67Percent,
  .width50Percent {
    width: 100%;
  }
}
